
function setTheme(theme:string){
    let localTheme=localStorage.getItem("zidxAdminTheme");
    if(theme=="dark"){
        ZIDX.$(".adminDarkThemeStylesheet").prop("disabled", false);
        ZIDX.$(".zidxHeaderLogoImg").each(function(this:HTMLImageElement){
            this.src=ZIDX.getVersionURL("/images/peakidx-app-icon.svg", false);
        });
    }else{
        ZIDX.$(".adminDarkThemeStylesheet").prop("disabled", true);
        ZIDX.$(".zidxHeaderLogoImg").each(function(this:HTMLImageElement){
            this.src=ZIDX.getVersionURL("/images/peakidx-app-icon.svg", false);
        });
    }
    if(localTheme!=theme){
        ZIDX.setCookie({key:"zidxAdminTheme",value:theme,futureSeconds:365*60 * 60 * 24,enableSubdomains:true});
        localStorage.setItem("zidxAdminTheme", theme);
    }
}
window.ZIDXOptions.loadOnceFunctions.push(function(){
    ZIDX.$(document).on("click", ".zidxAdminThemeLight", function(this:any, e:ClickEvent){
        e.preventDefault();
        setTheme("light");
        ZIDX.$(".zidxAdminThemeLight").addClass("zidxActive");
        ZIDX.$(".zidxAdminThemeDark").removeClass("zidxActive");
    });
    ZIDX.$(document).on("click", ".zidxAdminThemeDark", function(this:any, e:ClickEvent){
        e.preventDefault();
        setTheme("dark");
        ZIDX.$(".zidxAdminThemeLight").removeClass("zidxActive");
        ZIDX.$(".zidxAdminThemeDark").addClass("zidxActive");
    });
    let adminTheme=localStorage.getItem('zidxAdminTheme');
    if(adminTheme==null){
        try {
            if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                adminTheme = "dark";
            } else {
                adminTheme = "light";
            }
        }catch(e){
            adminTheme = "light";
        }
    }
    setTheme(adminTheme);
    ZIDX.$(".zidxAdminSidebarSelect").on("change", function(this:HTMLSelectElement, e:ChangeEvent){
        let siteId=this.options[this.selectedIndex].value;
        if(siteId!=ZIDX.options.selectedSiteId){
            let link=window.location.href.replace("adminSiteId=", "oldsid=");
            link=window.zidxBase.urlAppend(link, "adminSiteId="+siteId);
            window.location.href=link;
        }
    });
});
